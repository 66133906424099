<template>
  <div
    class="
      relative
      rounded-md
      bg-transparent
      p-2
      focus:outline-none
      border-0
      text-rosDok-ultra
    "
  >
    <img
      :src="$auth.getAvatar()"
      :title="`${$auth.getUserInfo().title} ${$auth.getFullName()}`"
      onerror="javascript:this.src='/svg/user.svg'"
      class="object-cover rounded-full cursor-pointer"
      :class="$auth.getAvatar() ? 'h-12 w-12' : ''"
      @click="
        () => {
          submit();
        }
      "
    />
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";

export default {
  mounted() {
    // Refresh navbar
    EventBus.$on("Emit_Refresh_navbar", () => {
      this.$forceUpdate();
    });
  },
  methods: {
    async submit() {
      this.$performTracking({
        data: {
          id: this.$auth.getUserId(),
          route: "SETTINGS_AVATAR",
          action: "GET",
          data: {},
        },
      });
      this.$router.push({ name: "settings" });
    },
  },
};
</script>

<style></style>
